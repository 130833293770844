<template>
  <div>
    <Loader v-show="loader"></Loader>
    <div class="card card-default mb-0">
      <div class="row bg-white no-gutters chat">
        <div class="col-lg-12 col-xl-12">
          <!-- Chat Left Side -->
          <div class="chat-left-side">

            <form class="chat-search" @submit.prevent="buscarDados()">
              <h2 class="heading-title mb-2 text-center">Digite seu {{chaveBusca.chave_busca_label}}</h2>
              <input v-if="chaveBusca.chave_busca_mascara" @dblclick="imprimirTesteDebounce()" type="tel" class="form-control form-control-lg text-center" placeholder="" v-mask="chaveBusca.chave_busca_mascara" v-model="textoBusca" /> 
              <input v-else @dblclick="imprimirTesteDebounce()" type="tel" class="form-control form-control-lg text-center" placeholder=""  v-model="textoBusca" /> <br>

              <button v-show="!participante" type="submit" class="mx-4 p-4  btn btn-lg btn-primary" style="width:95%">
                <i class="font-size-36 mdi mdi-database-search"></i> BUSCAR PARTICIPANTE
              </button>
              <button v-show="participante" @click="limparDados()" type="button" class="mx-4 p-4  btn btn-lg btn-secondary" style="width:95%">
                <i class="font-size-36 mdi mdi-refresh"></i> Limpar Busca
              </button>
            </form>

            <div v-if="participante" class="mb-5 px-6 pt-2 text-center" @dblclick="participante_editar = true">
              <hr>
              <div class="cores p-4" >
                <div v-if="participante_editar" class="row">
                  <div class="col-md-5 text-right">
                  <h2 class="mb-1" >Função:</h2>
                  <h2 class="mb-3" >Nome:</h2>
                  <h2 class="mb-3" >Nome no Crachá:</h2>

                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.cidade" class="mb-3" >Cidade:</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.estado" class="mb-3" >Estado:</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao" class="mb-3" >Instituição:</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao_cargo" class="mb-3" >Cargo:</h2>

                </div>
                <div class="col-md-7 text-left">
                  <h2 class="heading-title mb-2">
                    {{participante.funcao}}
                  </h2>
                  <h2 class="heading-title mb-2">
                    {{participante.nome}} {{participante.sobrenome}}
                  </h2>
                  <h2 class="heading-title mb-2">
                    <input class="form-control form-control-lg" type="text" v-model="participante.apelido_impressao" />
                  </h2>

                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.cidade" class="heading-title mb-2">
                    <input class="form-control form-control-lg" type="text" v-model="participante.cidade_impressao" />
                  </h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.estado" class="heading-title mb-2">
                    <input class="form-control form-control-lg" type="text" v-model="participante.estado_impressao" />
                  </h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao" class="heading-title mb-2">
                    <input class="form-control form-control-lg" type="text" v-model="participante.instituicao_impressao" />
                  </h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao_cargo" class="heading-title mb-2">
                    <input class="form-control form-control-lg" type="text" v-model="participante.instituicao_cargo_impressao" />
                  </h2>

                </div>


              </div>
              <div v-else class="row">
                <div class="col-md-5 text-right">
                  <h2 class="mb-1" >Função:</h2>
                  <h2 class="mb-1" >Nome:</h2>
                  <h2 class="mb-1" >Nome no Crachá:</h2>

                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.cidade" class="mb-1" >Cidade:</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.estado" class="mb-1" >Estado:</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao" class="mb-1" >Instituição:</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao_cargo" class="mb-1" >Cargo:</h2>

                </div>
                <div class="col-md-7 text-left">
                  <h2 class="heading-title mb-1">{{participante.funcao}}</h2>
                  <h2 class="heading-title mb-1">{{participante.nome}} {{participante.sobrenome}}</h2>
                  <h2 class="heading-title mb-1">{{participante.apelido_impressao}}</h2>

                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.cidade" class="heading-title mb-1">{{participante.cidade_impressao}}</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.estado" class="heading-title mb-1">{{participante.estado_impressao}}</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao" class="heading-title mb-1"> {{participante.instituicao_impressao}}</h2>
                  <h2 v-if="evento.configuracoes.cracha && evento.configuracoes.cracha.instituicao_cargo" class="heading-title mb-1">{{participante.instituicao_cargo_impressao}}</h2>

                </div>
              </div>

              </div>
              
      
              <div class="row">
                <div class="col-md-12 text-center mt-5">
                    <button type="button" class="mx-4 p-4 btn btn-lg btn-success" style="width:100%" @click="imprimir()">
                        <i class="font-size-36 mdi mdi-printer"></i>  Imprimir
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {ImpressaoService} from '../services'
import Loader  from '../components/Loader'

const impressaoService = new ImpressaoService();
export default {
  name: 'SecretariaView',
  components:{
    Loader
  },

  data(){
    return {
      participante: null,
      participante_editar: false,
      
      textoBusca: '',

      myStyleBackground: '#bababa',
      myStyleColor: '#000000',

      loader: false,
      debounce: 0
    }
  },

  mounted(){
    // this.textoBusca = '0001@CONGRESSOFT.COM';
    // this.buscarDados();
    this.limparDados();
  },

  methods:{

    imprimirTesteDebounce(){
      this.debounce++
      let timer = 4000 - (1000 * this.debounce);
      console.log(this.debounce, timer);

      setTimeout(() => {
        if(this.debounce > 0){
          this.debounce--
        } 
      }, timer);

      if(this.debounce == 3){
        this.imprimirTeste();
      }

    },

    imprimirTeste(){
      this.debounce = 0;
      this.loader = true;

      impressaoService.imprimirTeste().then( response => console.log(response)).catch((error) => console.error(error))
        .finally(() => {
          this.limparDados()
           setTimeout(() => {
             this.loader = false;
          //   this.$confirm({
          //     title: 'Confirmação de Impressão!',
          //     message: 'Sua etiqueta foi impressa corretamente?',
          //     button: {
          //       yes: 'Sim',
          //       no: 'Não'
          //     },
          //     callback: confirm => {
          //       if(confirm){
          //         setTimeout(() => {
          //           this.limparDados()
          //         }, 500);
          //       }
          //     }
          //   })
           }, 1500);
        })
    },

    // tentaBuscarDados : function(key){
    //   if(key.key == 'Enter'){
    //     this.buscarDados();
    //   }
    //   if(key.key == 'Escape'){
    //     setTimeout(() => {              
    //         this.limparDados()
    //     }, 500);
    //         return false;
    //   }
      
    // },

    limparDados () {
      this.participante = null;
      this.textoBusca = ''
      this.participante_editar = false
    },

    buscarDados (){
      this.participante_editar = false
      let texto = this.textoBusca.toLowerCase()
      let u = this.participantes.filter(x => {
        if(this.chaveBusca.chave_busca_somente_numeros)
          return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].replace(/\D/g,'') == texto.replace(/\D/g,'')) 
        if(this.chaveBusca.chave_busca_ignorar_pontuacao)
          return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].toLowerCase().replace(/[^a-z0-9]/gi,'') == texto.replace(/[^a-z0-9]/gi,'')) 

        return x[this.chaveBusca.chave_busca] && (x[this.chaveBusca.chave_busca].toLowerCase() == texto) 
      });
       
      if(u.length >= 1){
        this.participante = u[0];
        if(this.coresEvento[this.participante.funcao]){
          console.log(this.coresEvento[this.participante.funcao].cor_cracha)
          this.myStyleBackground = this.coresEvento[this.participante.funcao].cor_cracha
          this.myStyleColor = this.coresEvento[this.participante.funcao].cor_fonte
        }
        
        this.participante.apelido_impressao = this.participante.apelido ;
        this.participante.cidade_impressao = this.participante.cidade ;
        this.participante.instituicao_impressao = this.participante.instituicao;
        this.participante.instituicao_cargo_impressao = this.participante.instituicao_cargo;
        this.participante.estado_impressao = this.participante.estado;
        
      }else{
        this.$confirm({
          title: `${this.chaveBusca.chave_busca_label} Não Encontrado!`,
          message: `O ${this.chaveBusca.chave_busca_label} não encontrado! Favor dirija-se à secretaria do evento`,
          button: {
            yes: 'Ok'
          },
          callback: confirm => {            
              setTimeout(() => {     
                this.limparDados()
              }, 500);
            
          }
        })
      }
    },

    imprimir(){
      if(this.participante.bloqueia_auto_atendimento){
        this.$confirm({
          title: 'Impressão de Crachá!',
          message: 'Para imprimir seu crachá, dirija-se à secretaria!',
          button: {
            yes: 'Ok'
          },
          callback: confirm => {
              setTimeout(() => {     
                this.limparDados()
              }, 500);
          }
        })
        return false
      }
      if(!this.participante.liberado){
        this.$confirm({
          title: 'Impressão de Crachá!',
          message: 'Há um problema com a geração da sua etiqueta, Dirija-se à secretaria!',
          button: {
            yes: 'Ok'
          },
          callback: confirm => {
              setTimeout(() => {     
                this.limparDados()
              }, 500);
          }
        })
        return false
      }

      if(!this.reimprimirCracha && this.participante.credenciado_em){
        this.$confirm({
          title: 'Impressão de Crachá!',
          message: 'O seu crachá já foi impresso! para retirar uma segunda via dirija-se à secretaria!',
          button: {
            yes: 'Ok'
          },
          callback: confirm => {
              setTimeout(() => {     
                this.limparDados()
              }, 500);
          }
        })
        return false
      }

      this.loader = true;      
      impressaoService.imprimir(this.participante).then( response => console.log(response)).catch((error) => console.error(error))
        .finally(() => {
          this.$store.dispatch('credenciouParticipante', this.participante);
          
          setTimeout(() => {
            //alert("Desligando o loader")
            this.limparDados()
            this.loader = false;
          //   this.$confirm({
          //     title: 'Confirmação de Impressão!',
          //     message: 'Sua etiqueta foi impressa corretamente?',
          //     button: {
          //       yes: 'Sim',
          //       no: 'Não'
          //     },
          //     callback: confirm => {
          //       this.$store.dispatch('credenciouParticipante', this.participante);
          //       if(confirm){
          //         setTimeout(() => {
          //           this.limparDados()
          //         }, 500);
          //       }
          //     }
          //   })
           }, 1500);
        })

      
    },

  },

  computed: {
    ...mapGetters(['participantes','evento','chaveBusca','coresEvento','reimprimirCracha'])
  }

}
</script>
<style scoped>
  input{
    font-weight: 500;
    font-size: 22pt;
    color: #000000 !important;
  }

  .cores{
    background-color: v-bind(myStyleBackground) ;
    color: v-bind(myStyleColor) ;
  }
</style>

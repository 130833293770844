<template>
    <div>
        <div class="card card-default">
			<div class="card-header card-header-border-bottom">
				<h2>Participantes do Evento</h2>
			</div>

			<div class="card-body">
                <div class="row">
                    <div class="form-group col-md-10">
                        <label for="validationDefaultUsername">Digite um termo para pesquisar</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend2">
                                    <i class="mdi mdi-database-search"></i>
                                </span>
                            </div>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="validationDefaultUsername"
                                placeholder="Nome / Sobrenome / Email / CPF / Instituição / Cidade / Estado" 
                                aria-describedby="inputGroupPrepend2" 
                                v-model="termo_pesquisar"
                            >
                        </div>
                    </div>
                    <div class="form-group col-md-2 col-xs-4 text-right">
                        <label for="">&nbsp;</label>
                        <div class="input-group text-right" >
                            <button type="button" class="btn btn-outline-primary" title="Adicionar" @click="abrirModal(null)" data-toggle="modal" data-target="#modal-cadastro">
                                <i class=" mdi mdi-account-plus-outline mr-1"></i> Adicionar
                            </button>
                        </div>
                    </div>
                </div>
                <div style="width: 100%; overflow-x: auto">
                    <table class="table thead-dark table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Seq</th>
                                <th scope="col">Id</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Email</th>
                                <!-- <th scope="col">Institução</th> -->
                                <th scope="col">Função</th>
                                <th scope="col">CPF</th>
                                <th scope="col">Credenciado</th>
                                <th scope="col">Kit</th>
                                <th scope="col">Sinc</th>
                                <!-- <th scope="col">Ação</th> -->
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="p in participantesFiltrados" :key="p.id" @click="abrirModal(p)" style="cursor: pointer">
                                <td scope="row"> {{p.sequencia }}</td>
                                <td> {{p.id}} </td>
                                <td> {{p.nome}} {{p.sobrenome}} </td>
                                <td> {{p.email}}</td>
                                <!-- <td> {{p.instituicao }} </td> -->
                                <td> {{p.funcao }} </td>
                                <td style="white-space: nowrap"> {{p.cpf }} </td>
                                <td> <span v-if="p.credenciado_em">{{p.credenciado_em | timeformat }} </span>  </td>
                                <td> <span v-if="p.kit_retirado_em">{{p.kit_retirado_em | timeformat }} </span>  </td>
                                <td> 
                                    <span style="font-weight: 500 !important"  class="p-1 badge badge-success" v-if="p.sincronizar">Sim</span>
                                    <span style="font-weight: 500 !important"  class="p-1 badge badge-secondary" v-else>Não</span>
                                </td>
                                <!-- <td>
                                    <button type="button" class="mb-1 btn brn-sm btn-outline-info" title="Informações" @click="abrirModal(p)" >
                                        <i class="mdi mdi-information-outline"></i>
                                    </button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>

			</div>
		</div>

        <Modal v-if="showModal" @close="showModal = false">
            <h3 style="width: 100%" slot="header">Cadastro de Participante 
                <button @click="showModal=false" type="button" class="close float-right" aria-label="Close">
					<span aria-hidden="true">×</span>
				</button>
            </h3>
            <div slot="body">
                <div class="row">

                    <label class="col-md-2 col-xs-4 text-right">Id</label>
                    <div class="col-md-2 col-xs-4">
                        <input-componente type="text" v-model="participanteModal.id" :readonly="true" ></input-componente>
                    </div>
                    <div class="col-md-2 col-xs-4">
                        <input-componente type="text" v-model="participanteModal.sequencia" :readonly="true" ></input-componente>
                    </div>

                    <label class="col-md-2 col-xs-4 text-right">Função no Evento</label>
                    <div class="col-md-4 col-xs-8">
                        <container-componente :validator="$v.participanteModal.funcao" >
                            <select class="form-control" v-model="participanteModal.funcao">
                                <option v-for="f in funcoes" :key="f" :value="f">{{f}}</option>
                            </select>
                        </container-componente>
                    </div>

                    <label class="col-md-2 col-xs-4 text-right">Nome </label>
                    <div class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.nome" :validator="$v.participanteModal.nome" ></input-componente>
                    </div>

                    <label v-if="controleCampos.sobrenome.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.sobrenome.label}}</label>
                    <div v-if="controleCampos.sobrenome.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.sobrenome" :validator="$v.participanteModal.sobrenome" ></input-componente>
                    </div>

                    <label class="col-md-2 col-xs-4 text-right">Nome No Crachá</label>
                    <div class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.apelido" :validator="$v.participanteModal.apelido" ></input-componente>
                    </div>

                    <label class="col-md-2 col-xs-4 text-right">Email</label>
                    <div class="col-md-4 col-xs-8">
                        <input-componente type="text" :validator="$v.participanteModal.email" v-model="participanteModal.email" :readonly="!inserindo" ></input-componente>
                    </div>

                    <label v-if="controleCampos.cpf.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.cpf.label}}</label>
                    <div v-if="controleCampos.cpf.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.cpf" :validator="$v.participanteModal.cpf" :mask="controleCampos.cpf.mascara"></input-componente>
                    </div>

                    <label v-if="controleCampos.telefone.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.telefone.label}}</label>
                    <div v-if="controleCampos.telefone.exibir" class="col-md-4 col-xs-8">
                        
                        <input-componente type="text" v-model="participanteModal.telefone" :validator="$v.participanteModal.telefone" :mask="controleCampos.telefone.mascara"></input-componente>
                    </div>

                    <label v-if="controleCampos.instituicao.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.instituicao.label}}</label>
                    <div v-if="controleCampos.instituicao.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.instituicao" :validator="$v.participanteModal.instituicao"></input-componente>
                    </div>

                    <label v-if="controleCampos.instituicao_cargo.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.instituicao_cargo.label}}</label>
                    <div v-if="controleCampos.instituicao_cargo.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.instituicao_cargo" :validator="$v.participanteModal.instituicao_cargo"></input-componente>
                    </div>

                    <label v-if="controleCampos.cep.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.cep.label}}</label>
                    <div v-if="controleCampos.cep.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.cep" :validator="$v.participanteModal.cep" :mask="controleCampos.cep.mascara" ></input-componente>
                    </div>


                    <label v-if="controleCampos.bairro.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.bairro.label}}</label>
                    <div v-if="controleCampos.bairro.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.bairro" :validator="$v.participanteModal.bairro"></input-componente>
                    </div>

                    <label v-if="controleCampos.logradouro.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.logradouro.label}}</label>
                    <div v-if="controleCampos.logradouro.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.logradouro" :validator="$v.participanteModal.logradouro"></input-componente>
                    </div>
                    
                    <label v-if="controleCampos.numero.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.numero.label}}</label>
                    <div v-if="controleCampos.numero.exibir" class="col-md-4 col-xs-8">
                        <input-componente ref="numero" type="text" v-model="participanteModal.numero" :validator="$v.participanteModal.numero" :mask="controleCampos.numero.mascara"></input-componente>
                    </div>

                    <label v-if="controleCampos.complemento.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.complemento.label}}</label>
                    <div v-if="controleCampos.complemento.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.complemento" :validator="$v.participanteModal.complemento"></input-componente>
                    </div>

                    <label v-if="controleCampos.cidade.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.cidade.label}}</label>
                    <div v-if="controleCampos.cidade.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.cidade" :validator="$v.participanteModal.cidade"></input-componente>
                    </div>

                    <label v-if="controleCampos.estado.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.estado.label}}</label>
                    <div v-if="controleCampos.estado.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.estado" :validator="$v.participanteModal.estado"></input-componente>
                    </div>

                    <label v-if="controleCampos.pais.exibir" class="col-md-2 col-xs-4 text-right">{{controleCampos.pais.label}}</label>
                    <div v-if="controleCampos.pais.exibir" class="col-md-4 col-xs-8">
                        <input-componente type="text" v-model="participanteModal.pais" :validator="$v.participanteModal.pais"></input-componente>
                    </div>

                </div>
            </div>
            <div slot="footer" class="modal-footer">
                <div class="row">
                    <div class="col-md-12" v-if="mensagemErro || mensagemSucesso">
                        <Mensagem v-if="mensagemErro" :erro="mensagemErro" @fechar="mensagemErro = null"></Mensagem>
                        <Mensagem v-if="mensagemSucesso" :erro="mensagemSucesso" @fechar="mensagemSucesso = null"></Mensagem>
                    </div>
                    <div class="col-md-12">
                        <button type="button" class="mx-4 p-4 btn btn-success float-left" @click="imprimir()" v-if="participanteModal.id > 0" >Imprimir</button>
                        <button type="button" class="mx-4 p-4 btn btn-secondary" @click="showModal=false">Fechar</button>
                        <button @click="salvar()" v-if="inserindo" type="button" class="mx-4 p-4 btn btn-primary">Salvar</button>
                    </div>
                </div>
            </div>
            
        </Modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from './Modal'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import Mensagem from '../components/Mensagem'
import {EnderecoService, ImpressaoService, ParticipanteService} from '../services'

// const impressaoService = new ImpressaoService();
// const participanteService = new ParticipanteService()
// const enderecoService = new EnderecoService()

export default {
    name: 'Participantes',
    components:{
        Modal,
        Mensagem,
    },
    data(){
        return {
            showModal: false,
            participanteModal: null,
            mascara: '###.###.###-##',
            termo: '',
            termo_pesquisar: '',
            timer: null,

            mensagemErro: null,
            mensagemSucesso: null
        }
    },

    validations(){
        let participanteModal = {
            funcao: {required},
            nome: {required, maxLength: maxLength(200)},
            sobrenome: { maxLength: maxLength(200)},
            apelido: {required, maxLength: maxLength(30)},
            email: {required, email,  maxLength: maxLength(255)},
            cpf: { maxLength: maxLength(20)},
            telefone: { maxLength: maxLength(30)},
            instituicao: {maxLength: maxLength(200)},
            instituicao_cargo: {maxLength: maxLength(200)},
            cep: { maxLength: maxLength(10)},
            bairro: { maxLength: maxLength(100)},
            logradouro: { maxLength: maxLength(100)},
            numero: { maxLength: maxLength(10)},
            cidade: { maxLength: maxLength(100)},
            estado: { maxLength: maxLength(50)},
            pais: { maxLength: maxLength(50)},
            complemento: { maxLength: maxLength(200)},
        };

        Object.keys(this.controleCampos).forEach(key => {
            if(this.controleCampos[key].obrigatorio && this.controleCampos[key].exibir)
                participanteModal[key].required = required
        })

        return {
            participanteModal: participanteModal
        }
    },

    methods:{

        salvar(){
            this.mensagemErro = null
            this.mensagemSucesso = null
            this.$v.participanteModal.$touch();
            if(this.$v.participanteModal.$invalid){
                this.mensagemErro = "Verifique os campos do formulário";
                return false
            }
            const participanteService = new ParticipanteService()
            participanteService.criar(this.evento.id, this.participanteModal).then(response => {
                this.participanteModal = response.data;
                this.participanteModal.id = response.data.id;
                this.$forceUpdate();
                this.$confirm({
                title: 'Impressão de Etiqueta!',
                message: `Deseja imprimir a etiqueta do usuário ${this.participanteModal.nome} ? `,
                button: {
                    yes: 'Sim',
                    no: 'Não'
                },
                callback: confirm => {
                    if(confirm){
                    this.imprimir()
                    }
                }
                })
            }).catch(error => {
                console.log(error, error.response)
                if(error.response.status === 401){
                this.$root.logar();
                this.mensagemErro = `Erro ao tentar salvar os dados, estamos tentando salvar novamente... aguarde alguns instantes`
                setTimeout(() => {
                    this.salvar();
                }, 2000);
                return false;
                }
                this.mensagemErro = `Erro ao tentar salvar os dados Entre em contato com o suporte!`
            })
        },

        abrirModal (participante = null) {
            this.showModal = true,
            this.mensagemErro = null
            this.mensagemSucesso = null
            if(participante == null){
                this.participanteModal = {
                    id: '',
                    sequencia: '',
                    funcao: '',
                    nome: '',
                    sobrenome: '',
                    apelido: '',
                    email: '',
                    cpf: '',
                    telefone: '',
                    instituicao: '',
                    instituicao_cargo: '',
                    cep: '',
                    bairro: '',
                    logradouro: '',
                    numero: '',
                    cidade: '',
                    estado: '',
                    pais: '',
                    complemento: '',
                }
                return
            }

            this.participanteModal = {...participante}
        },

        imprimir:function(){

            const impressaoService = new ImpressaoService();
            impressaoService.imprimir(this.participanteModal)
                .then( response => console.log(response))
                .catch((error) => console.error(error))
                .finally(() => {
                    this.$store.dispatch('credenciouParticipante', this.participanteModal);
                     setTimeout(() => {
                         this.loader = false;
                    //     this.$confirm({
                    //     title: 'Confirmação de Impressão!',
                    //     message: 'Sua etiqueta foi impressa corretamente?',
                    //     button: {
                    //         yes: 'Sim',
                    //         no: 'Não'
                    //     },
                    //     callback: confirm => {
                    //         if(confirm){
                    //             this.$store.dispatch('credenciouParticipante', this.participanteModal);
                    //         }
                    //     }
                    // })
                 }, 1500);
            })
        },

        pesquisarDebounce () {
            clearTimeout(this.timer);
            this.timer = setTimeout( () => {
                this.termo = this.termo_pesquisar
            },800);
        },

        campo(campo){
            if(this.campos.length === 0)
                return false
            let indice = this.campos.map(x => x.campo).indexOf(campo)
            if(indice < 0)
                return false
            return this.campos[indice]
        },

        buscarCep: function(){
        const enderecoService = new EnderecoService()
        enderecoService.buscarEnderecoPorCep(this.participanteModal.cep)
          .then((response) => {          
            if(response.data.logradouro){
                this.participanteModal.logradouro = response.data.logradouro.substr( response.data.logradouro.indexOf(' ')+1 );
                this.participanteModal.cidade = response.data.localidade
                this.participanteModal.estado = response.data.uf
                this.participanteModal.bairro = response.data.bairro;
                this.participanteModal.pais = "Brasil";
                this.$refs.numero.focus()
            }else{
                this.participanteModal.logradouro = ''
                this.participanteModal.cidade = ''
                this.participanteModal.estado = ''
                this.participanteModal.bairro = ''
                this.participanteModal.pais = '';
            }
          })
          .catch((error) => {
              console.log(error)
              this.participanteModal.logradouro = ''
              this.participanteModal.cidade = ''
              this.participanteModal.estado = ''
              this.participanteModal.bairro = ''
              this.participanteModal.pais = '';
          });
    },
        
    },

    watch: {
        termo_pesquisar: function(val){
            this.pesquisarDebounce()
        },

        'participanteModal.email': function(val){
            this.participanteModal.email = String(val).toLowerCase()
        },
        'participanteModal.apelido': function(val){
            this.participanteModal.apelido = String(val).toUpperCase()
        },
        'participanteModal.cep': function(val){
            if(!val)
                return false
            let cep = val.replace(/\D/g, '')
            if(cep.length == 8) 
                this.buscarCep()
        }
    },

    computed:{
        ...mapGetters(['participantes','evento','campos', 'funcoes']),
        inserindo(){
            return this.participanteModal && !(this.participanteModal.id > 0)
        },

        participantesFiltrados(){
            if(this.termo.length === 0)
                return this.participantes

            let termoMinusculo = this.termo.toLowerCase().trim()
            let termoNumeros = this.termo.replace(/\D/g,'')

            return this.participantes.filter(x => {
               let nome_completo = x.nome;
               if(x.sobrenome)
                nome_completo += " " + x.sobrenome
                nome_completo = nome_completo.toLowerCase();
                return   nome_completo.includes(termoMinusculo) ||
                        // (x.nome && x.nome.toLowerCase().includes(termoMinusculo) ) ||
                        //  (x.sobrenome && x.sobrenome.toLowerCase().includes(termoMinusculo) ) ||
                         (x.apelido && x.apelido.toLowerCase().includes(termoMinusculo) ) ||
                         (x.email && x.email.toLowerCase().includes(termoMinusculo) ) ||
                         (x.estado && x.estado.toLowerCase().includes(termoMinusculo) ) ||
                         (x.cidade && x.cidade.toLowerCase().includes(termoMinusculo) ) ||
                         (x.instituicao && x.instituicao.toLowerCase().includes(termoMinusculo) ) ||
                         (termoNumeros.length > 0 && x.cpf && x.cpf != null  && x.cpf.replace(/\D/g,'').includes(termoNumeros) )                         

            })
        },

        controleCampos(){
            
            let campos = {
                cpf : { label: 'CPF', label_fixa: 'CPF',exibir: true, obrigatorio: false, mascara: ''},
                telefone : { label: 'Telefone', label_fixa: 'Telefone',exibir: true, obrigatorio: false, mascara: ''},
                instituicao : { label: 'Instituição', label_fixa: 'Instituição',exibir: true, obrigatorio: false, mascara: ''},
                instituicao_cargo : { label: 'Cargo na Instituição', label_fixa: 'Cargo na Instituição',exibir: true, obrigatorio: false, mascara: ''},
                sobrenome : { label: 'Sobrenome', label_fixa: 'Sobrenome',exibir: true, obrigatorio: false, mascara: ''},
                cep : { label: 'CEP', label_fixa: 'CEP',exibir: true, obrigatorio: false, mascara: ''},
                logradouro : { label: 'Logradouro', label_fixa: 'Logradouro',exibir: true, obrigatorio: false, mascara: ''},
                numero : { label: 'Numero', label_fixa: 'Numero',exibir: true, obrigatorio: false, mascara: ''},
                bairro : { label: 'Bairro', label_fixa: 'Bairro',exibir: true, obrigatorio: false, mascara: ''},
                cidade : { label: 'Cidade', label_fixa: 'Cidade',exibir: true, obrigatorio: false, mascara: ''},
                estado : { label: 'Estado', label_fixa: 'Estado',exibir: true, obrigatorio: false, mascara: ''},
                pais : { label: 'País', label_fixa: 'País',exibir: true, obrigatorio: false, mascara: ''},
                complemento : { label: 'Complemento', label_fixa: 'Complemento',exibir: true, obrigatorio: false, mascara: ''},
            }

            Object.keys(campos).forEach(key => {
                let campo =  this.campo(key);                
                if(campo){
                    campos[key] = campo;
                }
            })
            return campos
        }
    }
};
</script>
<style scoped>
    label{
        font-weight: 800;
        padding-top:5px
    }
</style>
